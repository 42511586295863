import { createGetKcContext } from 'keycloakify/login'

export interface KcContextExtension {
	pageId: 'login.ftl'
	client?: {
		attributes?: Record<string, string>
	}
}
export const { getKcContext } = createGetKcContext<KcContextExtension>({
	mockData: [
		{
			pageId: 'error.ftl',
			locale: {
				currentLanguageTag: 'sv'
			}
		}, {
			pageId: 'info.ftl',
			locale: {
				currentLanguageTag: 'sv'
			},
			messageHeader: 'messageAlreadyLoggedIn'
		}, {
			pageId: 'login.ftl',
			realm: {
				name: 'external'
			},
			client: {
				// eslint-disable-next-line no-template-curly-in-string
				name: '${client_account-console}'
			},
			social: {
				providers: [{
					loginUrl: 'https://weblogin.smhi.se',
					alias: 'internaloidc',
					providerId: 'internaloidc',
					displayName: 'Internal User'
				}]
			},
			locale: {
				currentLanguageTag: 'sv'
			}
		},
		{
			pageId: 'login-reset-password.ftl',
			locale: {
				currentLanguageTag: 'sv'
			}
		},
		{
			pageId: 'login-update-password.ftl',
			locale: {
				currentLanguageTag: 'sv'
			}
		},
		{
			pageId: 'login-otp.ftl',
			locale: {
				currentLanguageTag: 'sv'
			},
			auth: {
				attemptedUsername: 'e1357'
			},
			otpLogin: {
				userOtpCredentials: [
					{ id: '1', userLabel: 'Google Authenticator' },
					{ id: '2', userLabel: 'Authy Desktop' }
				]
			}
		},
		{
			pageId: 'login-config-totp.ftl',
			locale: {
				currentLanguageTag: 'sv'
			},
			message: {
				type: 'warning',
				summary: 'Du behöver konfigurera mobilautentiseraren för att aktivera ditt konto.'
			},
			isAppInitiatedAction: false
		},
		{
			pageId: 'login-page-expired.ftl',
			locale: {
				currentLanguageTag: 'sv'
			}
		},
		{
			pageId: 'saml-post-form.ftl',
			locale: {
				currentLanguageTag: 'sv'
			}
		}]
})

export const { kcContext } = getKcContext({
	// Uncomment to test the login page for development.
	mockPageId: 'login.ftl'
})

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>
