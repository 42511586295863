import { createRoot } from 'react-dom/client'
import { StrictMode, lazy, Suspense } from 'react'
import { kcContext as kcLoginThemeContext } from './login/kcContext'

const KcLoginThemeApp = lazy(async () => await import('./login/KcApp'))

const root = document.getElementById('root')
if (root === null) {
	throw new Error('Failed to locate root element')
} else {
	createRoot(root).render(
		<StrictMode>
			<Suspense>
				{((): JSX.Element => {
					const kcContext = kcLoginThemeContext !== undefined ? <KcLoginThemeApp kcContext={kcLoginThemeContext} /> : undefined
					if (kcContext === undefined) {
						throw new Error('This app is a Keycloak theme. It is not meant to be deployed outside of Keycloak')
					} else {
						return kcContext
					}
				})()}
			</Suspense>
		</StrictMode>
	)
}
